.login {
    background-color: white;
    opacity: 1;
    border: 1px solid #444;

    padding: 10px;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
    width: 500px;
    position: relative;
}

.md-inputfield > .p-password {
    margin-bottom: 20px;
}

.md-inputfield > .p-password,
.md-inputfield > .p-password > input {
    width: 100%;
}

.login-blur {
    filter: blur(4px);
    -webkit-filter: blur(4px); 
}

.impressum-content a,
.datenschutz-content a {
    text-decoration: underline;
}

.login-page.impressum {
    align-items: flex-start;
}

.datenschutz-content > h1, h2, h3, h4, h5, h6 {
    font-size: revert;
}
.impressum-content > h1, h2, h3, h4, h5, h6 {
    font-size: revert;
}

.datenschutz-content {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.impressum-content {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.backtologin-button {
    margin-left: 40px;
    margin-bottom: 40px;
}

.login.impressum,
.login.datenschutz {
    margin-top: 20px;
    width: 80%;
    min-width: 400px;
    height: calc(100% - 40px);
    overflow-y: auto;
}

.login-topbar {
    height: 70px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    align-items: center;
    font-size: 2.2em;
    color: #666;
    font-weight: lighter;
    letter-spacing: -1px;
    border-bottom: 1px solid lightgray;
    justify-content: center;
    padding-bottom: 10px;
}

.login-content {
    margin: 50px;
    
}

.login-content-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 50px;
}

.md-inputfield > input {
    width: 100%;
    margin-bottom: 20px;
}

.btn-login {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.login-absolute-center{
    background: #FFF;
    position: absolute;
    width: 100%;
    border-radius: 0px;
    display: flex;
    align-items: center;
    top: 80px;
    left: 0px;
    bottom: 0px;
}

.login-success-message{
    background-color: #a5d7a7;
    color: #4caf50;
    border: 3px dashed #4caf50; 
    padding: 10px;
    font-weight: bold;
    font-size: 1.1em;
    margin: 10px;
    position: absolute;
    border-radius: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    bottom: 0px;
    left: 0;
    right: 0;
}

.login-progress-spinner {
    margin-top: -30px !important;
}

.login-error {
    padding: 0px !important;
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid red;
    color: red;
    font-weight: bold;
}

.login-version {
    display: flex;
    justify-content: center;
    align-items: center;
}

.impressumdatenschutz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.impressumdatenschutz > .link-button {
    margin-right: 10px;
    margin-left: 10px;
}

.overlay-cookiebanner {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #424242;
    display: block;
    opacity: 0.5;
    filter: alpha(opacity=50);
    width: 100%;
    height: 100%;
    overflow: hidden;
}