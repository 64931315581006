.dashboard-toolbar {
    border-bottom: 1px solid lightgray;
    background-color: white;
    width: calc(100% + 5px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.dashboard-togglebuttons-right {
    border-left: 1px solid lightgray;
    padding-left: 30px;
    padding-right: 10px;
}

.dashboard-togglebuttons-right,
.dashboard-togglebuttons-left {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-toolbar-toggle:not(svg) {
    border: 1px solid #757575;
    border-radius: 10px;
    margin: 8px 5px;
    padding: 4px 10px;
}

.dashboard-toolbar-calendar-button {
    position: relative;
}

.dashboard-toolbar-calendar.react-calendar {
    max-width: none;
    position: absolute;
    top: 33px
}

.react-calendar__month-view__weekdays__weekday {
    color: black;
}

.dashboard-toolbar-calendar-hidden.react-calendar {
    display: none;
}

.dashboard-toolbar-toggle-active {
    background-color: #537994;
    color: white;
}

.dashboard-toolbar-toggle:hover {
    box-shadow: 0px 0px 0px 2px #537994;

}

.dashboard-toolbar > .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token .p-autocomplete-token-label,
.dashboard-toolbar > .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token > input,
.dashboard-toolbar > .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-item:hover, 
.dashboard-toolbar > .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-item.p-highlight {
    font-weight: lighter;
    font-size: 1.1em;
}

body .dashboard-togglebuttons-left .p-multiselect-panel .p-multiselect-header {
    background-color: #537994;
}

body .dashboard-togglebuttons-left .p-multiselect .p-multiselect-label-container .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
}

.lastupdated-selected-category {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 2px 4px;
}

.lastupdated-no-selected-category {
    border-radius: 4px;
    padding: 5px 4px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.lastupdated-selected-category > svg {
    margin-right: 5px;
}

.lastupdated-selected-category > button {
    margin-left: 5px;
}

body .dashboard-togglebuttons-left .p-multiselect{
    border-bottom: none;
}

body .dashboard-togglebuttons-left .p-multiselect .p-multiselect-panel {
    min-width: unset;
    top: 35px !important;
}

body .dashboard-togglebuttons-left .p-multiselect .p-multiselect-label-container .p-multiselect-label > .lastupdated-selected-category {
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.lastupdated-selected-category-close {
    margin-top: -3px;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
    max-height: 500px !important;
}

.dashboard-toolbar-text {
    /* min-width: 100px; */
    margin-right: 15px;
}



.dashboard-toolbar-tags {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.dashboard-toolbar-left {
    display: flex;
    align-items: center;
}

.dashboard-toolbar-right .p-autocomplete {
    min-width: 400px;
}

.p-autocomplete-item > .dashboard-toolbar-suggestion > svg {
    margin-right: 5px;
}

.taglist-item > svg {
    margin-right: 5px;
}

.dashboard-toolbar-suggestion {
    padding: 15px;
}

.p-autocomplete-item:hover > .dashboard-toolbar-suggestion {
    background-color: #7297b3;
    color: white;
}

.p-autocomplete-item.p-highlight > .dashboard-toolbar-suggestion > svg > path {
    fill: white !important;
}
.p-autocomplete-item:hover > .dashboard-toolbar-suggestion > svg > path {
    fill: white !important;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-inputtext {
    width: 100%;
}

.dashboard-toolbar > * > .icon-button {
    border: 1px solid lightgray;
    padding: 3px 10px;
    margin: 5px;
    border-radius: 3px;
    color: #555;
    transition: color .1s ease-in-out, background-color .1s ease-in-out;
}

.switch-button > .p-inputswitch {
    margin-top: 3px;
    margin-bottom: 3px;
}

.dashboard-toolbar > * > .icon-button.disabled {
    color: #888;
    cursor: default;
}


.dashboard-toolbar > * > .icon-button > .material-icons {
    margin-right: 5px;
}

.dashboard-toolbar > * > .icon-button:hover {
    color: white;
    background-color: #7297b3;
}

.dashboard-toolbar > * > .icon-button.disabled:hover {
    color: #888;
    background-color: white;
}

.dashboard-toolbar > * > .icon-button.toggle {
    color: white;
    background-color: #7297b3;
}

.dashboard-toolbar > * > .icon-button:active {
    color: white;
    background-color: #86b6da;
}