.topbar {
    height: 50px;
    background: white;
    border-bottom: 1px #aaa solid;
    float: right;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 85px;
}

.topbar-item {
    height: 100%;
    display: flex;
    align-items: center;
    animation-delay: .2s;
}

.topbar-title {
    cursor: pointer;
    position: relative;
    left: -50%;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 2.2em;
    color: #666;
    font-weight: lighter;
    letter-spacing: -1px;
    height: 100%;
}

.topbar-logo
{
    height: 100%;
    /* width: 65px; */
}

.topbar-logo > img
{
    height: 100%;
    padding: 6px;
}

.destatis-logo > img
{
    width: 100%;
}

.topbar-path 
{
    /* height: 50px;
    position: absolute;
    left: 95px; */
    /* text-align: center; */
    display: flex;
    align-items: center;
    font-size: 1.8em;
    color: #666;
    font-weight: lighter;
    letter-spacing: -1px;
    height: 100%;
    padding-right: 14px;
}

.topbar-path-icon 
{
    font-size: 1em;
    margin-right: 10px;
}

.topbar-burger-menu
{
    display: none;
    align-items: center;
    /* height: 100%; */
}


.topbar-item-titel {
    position: absolute;
    height: 50px;
    left: 50%;
}

.topbar-item .topbar-burger-menu-button {
    background-color: transparent;
    box-shadow: none;
    font-size: 1.2em;
}

.topbar-item .topbar-add-dashboard-tile-button {
    background-color: transparent;
    box-shadow: none;
}

.topbar-item .topbar-burger-menu-button .pi,
.topbar-item .topbar-add-dashboard-tile-button .pi {
    color: #666;
}

.topbar-item .topbar-burger-menu-button:enabled:not(:focus):hover,
.topbar-item .topbar-burger-menu-button:focus,
.topbar-item .topbar-add-dashboard-tile-button:enabled:not(:focus):hover,
.topbar-item .topbar-add-dashboard-tile-button:focus {
    background-color: transparent;
}

.topbar-add-dashboard-tile
{
    /* align-items: center; */
    /* height: 100%; */
    /* padding-left: 14px; */
}

.topbar-item .topbar-add-dashboard-tile-button.p-button-icon-only {
    border: 1px solid #afafaf;
    border-radius: 2px;
}