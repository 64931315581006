.sidebar{
    width: 75px;
    position: fixed;
    height: 100%;
    float: left;
    border-right: 1px solid #444;
    z-index: 100;


    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: inherit;
    
    animation-delay: .2s;
}

.sidebar_logo {
    width: 75px;
    height: 65px;
}

.sidebar_logo:hover {
    background-color: #eeeeee42;
}

.sidebar_buttons {
    width: calc(100% - 1px);
    height: 50px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
}

.sidebar-category {
    min-height: 60px;
}

.sidebar_buttons > *,
.sidebar_buttons > .sidebar-category > * {
    margin-top: 5px;
}

.sidebar-button {
    height: 60px;
    position: relative;
}

.sidebar-button:last-child {
    background-image: url(./img/trianglify3.svg);
}

.sidebar_top{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar_bottom{
    display: block;
    margin-bottom: 20px;
}

.sidebar_buttons > .sidebar-button > .p-link,
.sidebar_buttons > .sidebar-category > .sidebar-button > .p-link {
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;
    border-left: 4px solid #6ea0c300;
    height: 100%;
}

.sidebar_buttons > .sidebar-button > .p-link.hover,
.sidebar_buttons > .sidebar-category > .sidebar-button > .p-link.hover {
    background-color: #eeeeee42;
    border-left: 4px solid #6ea0c3;
}

.sidebar_buttons > .sidebar-button > .p-link > .material-icons,
.sidebar_buttons > .sidebar-button > .p-link > svg,
.sidebar_buttons > .sidebar-category > .sidebar-button > .p-link > .material-icons,
.sidebar_buttons > .sidebar-category > .sidebar-button > .p-link > svg{
    color: white;
    font-size: 2.5em;
    margin-left: -4px;
    width: 33px;
    height: 33px;
}

.sidebar_buttons > .sidebar-button > .p-link > svg {
    width: 43px;
    height: 43px;
}

.hover-menu {
    position: absolute;
    top: 0;
    margin-top: 0px;
    left: 75px;
    /*color: rgb(231, 231, 231);*/
    color: #666;
    /*background-color: #537994;*/
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    border-top: 1px solid rgb(155, 155, 155);
    border-right: 1px solid rgb(155, 155, 155);
    border-bottom: 1px solid rgb(155, 155, 155);
}

.hover-menu.hidden {
    visibility: hidden;
}
.hover-menu-disabled > .hover-menu-title.top {
    background-color: gray;
    color: white;
}

.hover-menu-title:hover {
    color: white;
    background-color: #7297b3;
    /*text-shadow: -0.06ex 0 white, 0.06ex 0 white;*/
}

.hover-menu-title {
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.1em;
    /*font-size: 1.2em;*/
}

.hover-menu-title-icon {
    font-size: '25px';
    margin-right: 10px;
    margin-left: -10px;
}

.hover-menu-title.top {
    /*background-color: #7297b3;*/
    background-color: #fff;
    color: #666;
}    

.content-sidebar-mobile-active {
    transition: transform 0.5s;
    transform: translateX(0);
}


.card-title-category-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    margin-left: -16px;
    border-bottom: 1px solid gray;
    width: 35px;
}

.category-button:hover {
    filter: brightness(110%);
}
.category-button:active {
    filter: brightness(120%);
}

.sidebar-dashboard-button {
    position: relative;
}

.dashboard-menu {
    position: absolute;
    margin-top: 0;
    top: 0;
}

.dashboard-menu-title {
    position: relative;
}

.extension-button {
    padding: 0px !important;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.extension-button-bg {
    width: 50%;
    height: 50%;

}

.extension-button-icon {
    position: absolute;
    top: 13px;
    left: 20px;
}