.my-dashboards-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}


.my-dashboards {
    border: 1px solid lightgray;
    background-color: white;
    margin-top: 40px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    color: #666;
    font-size: 20px;
    font-weight: lighter;
    width: 33%;
    flex-direction: column;
    min-width: 450px;
}

.my-dashboards-notfound {
    text-align: center;
    margin-top: 40px;
}

.my-printlayouts {
    border: 1px solid lightgray;
    background-color: white;
    margin-top: 40px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    color: #666;
    font-size: 20px;
    font-weight: lighter;
    width: 32%;
    flex-direction: column;
    min-width: 450px;
}

.my-printlayouts-title {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.my-printlayouts > .search-list-mode {
    margin-top: 30px;
}

.my-dashboards > .search-list-mode {
    margin-top: 25px;
}

.my-dashboards-title {
    margin-top: 10px;
    margin-left: 16px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.my-dashboards-title > span {
    margin-left: 10px;
}


.my-dashboards > .p-tree {
    width: 100%;
    border: none;
}


.my-dashboards-entry {
    /* border: 1px solid lightgray; */
    /* margin: 5px; */
    /* background-color: #537994; */
    -webkit-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
-moz-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
    background-color: #f5efef;
    width: 100%;
    /* color: white; */
    color: #212121;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
}

.my-dashboards-entry-tags {
    flex-grow: 1;
    align-items: flex-end;
    text-align: end;
    margin-right: 10%;
    display: flex;
    justify-content: flex-end;
}

.my-dashboards-entry-tag {
    font-size: 0.8em;
    /* padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px; */
    padding: 2px 5px;
    border-radius: 3px;
    /* background-color: #e6511a; */
    background-color: #7297b3;
    color: #fff;
    /* border: 1px solid lightgray; */
    margin-left: 5px;
    height: 80%;
}


.search-content-text > svg {
    margin-left: 10px;
}

.search-content-delete {
    margin-right: 10px;
}

.search-content-text {
    display: flex;
    width: 100%;
    align-items: center;
}

.my-dashboards-entry-title {
    margin: 10px;
    flex-grow: 1;
}
.my-dashboards-entry-abo {
    margin-right: 10px;
    margin-top: -5px;
}

.my-dashboards-entry:hover {
    /* background-color: #7297b3; */
    background-color: #e8e4e4;
}

.my-dashboards-display-mode-button {
    box-shadow: none !important;
    border: 1px solid lightgray !important;
    padding: 3px 10px !important;
    /* border-radius: 3px !important; */
    /* margin-right: 0 !important; */
}


.my-dashboards-display-mode-button.button-inactive {
    background-color: transparent !important;
}

.my-dashboards-display-mode-button.button-active {
    background-color: lightgray !important;
}

.my-dashboards-display-mode-button .pi{
    color: #666 !important;
}


.my-dashboards-title .my-dashboards-display-mode-button:first-child {
    border-radius: 3px 0px 0px 3px !important;
    margin-right: 0 !important;
}

.my-dashboards-title .my-dashboards-display-mode-button:nth-child(2) {
    border-radius: 0px 3px 3px 0px !important;
}