.printboard {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.printboard-blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: blur(4px);
    -webkit-filter: blur(4px); 
}

.printboard-title {
    margin: 20px 0px;
    font-size: 2.2em;
    color: #666;
    font-weight: lighter;
    letter-spacing: -1px;
    display: flex;
    width: 1000px;
}

.printboard-container {
    display: flex;
    position: relative;
}

.printboard-pageindicator {
    /* width: calc(100% + 120px); */

    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    /* margin-left: -60px; */
    pointer-events: none;
}

.printlayout-save-dialog {
    margin-left: 50px;
}

.printlayout-save-dialog-overridelabel {
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(216, 0, 0);
}

.printlayout-save-dialog .printlayout-save-dialog-input {
    width: 400px;
    
}

.printboard-firstpage {
    height: 100%;
    max-height: 1331px;
    background: repeating-linear-gradient(transparent, transparent 1320px, black 1320px,black 1321px, #f0f0f0 1321px, #f0f0f0 1330px,black 1330px,black 1331px);
}

.printboard-nextpages{
    height: auto;
    flex-grow: 1;
    background: repeating-linear-gradient(transparent, transparent 1490px, black 1490px,black 1491px, #f0f0f0 1491px, #f0f0f0 1500px,black 1500px,black 1501px);
}

.printlayout {
    margin: 0px !important;
}

.printboard-title-right {
    align-items: center;
    display: flex;
    font-size: 1.2em;
}

.printboard-title-center {
    flex-grow: 1;
    text-align: center;
}

.printboard-title-right > .p-link {
    color: #666;
    font-size: 0.5em;
    font-weight: lighter;
}
.printboard-title-right.disabled > .p-link {
    color: rgb(185, 185, 185);
    cursor: default;
}

.printbutton-loading > .p-progress-spinner-svg > .p-progress-spinner-circle {
    stroke: #666 !important;
}

.printboard-review {
    width: 1002px;
    background-color: white;
    border: 1px solid gray;
}

.printboard-toprint {
    width: 1000px;
}

.dashboard-tile-overlay {
    background-color: rgba(83, 121, 148, 0.63);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: background-color ease-in-out 0.1s;
    flex-flow: column;
    font-size: 1.5vw;
    color: white;
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
}

.dashboard-tile-overlay.unchecked {
    background-color: rgba(148, 83, 83, 0.63);
}

.print-preview-loading-overlay {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #42424285;
    display: flex;
    justify-content: center;
    align-items: center;
}

.print-preview-loading-overlay-title {
    font-size: 2.5em;
    color: #404040;
    letter-spacing: -1px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.print-legend {
    padding: 10px 10px 0px 10px;
}

.print-legend-content {
    border: 1px solid gray;
    padding: 0px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.print-legend-category {
    display: flex;
    align-items: center;
    width: 50%;
    padding-top: 10px;
}

.print-legend-category-icon {
    width: 50px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.print-legend-category-text {
    margin-top: -5px;
    margin-left: 10px;
    margin-right: 25px;
    font-size: 1.2em;
    letter-spacing: -.2px;
    line-height: normal;
}