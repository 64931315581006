.pdf-content {
    text-align: center;
}

.pdf-toolbar {
    background-color: white;
    display: flex;
    padding: 5px;
    margin: 10px;
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid lightgray;
}

.pdf-toolbar > div > * {
    padding: 5px;
}

.pdf-toolbar > div {
    display: flex;
    align-items: center;
    margin-right: 15px;
}