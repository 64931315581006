.cookiebanner-root {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* bottom: 0;
    left: 0;
    right: 0; */
    display: flex;
    justify-content: center;
    z-index: 10;
}

.cookiebanner-root.show-datenschutz {
    height: 90%;
    width: 90%;
}

.cookiebanner-datenschutz {
    padding-bottom: 20px;
}

.cookiebanner-logo
{
    height: 70px;
    /* width: 113px; */
    width: 140px;
    background-image: url("./img/DashboardDeutschlandLogo.png");
    /* background-size: 112px; */
    background-size: 140px;
    background-repeat: no-repeat;
    /* background-position-y: 14px; */
    background-position-y: 10px;
}

.cookiebanner-title {
    line-height: 40px;
    text-align: center;
    margin: 15px;
}

.cookiebanner-text {
    text-align: center;
}

.show-datenschutz > .cookiebanner-content {
    width: 90%;
    max-width: unset;
    align-items: center;
    overflow-y: auto;
}

.cookiebanner-content {
    background-color: white;
    max-width: 900px;
    padding: 20px;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}

.cookiebanner-button {
    margin-top: 20px;
    margin-left: 10px;
}