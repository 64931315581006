.category-root {
    display: flex;
    background-color: white;
    height: 100%;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
}

.category-header {
    border: 1px solid lightgray;
    margin-top: 50px;
    padding: 10px 18px;
    border-radius: 3px;
    display: flex;
    color: #666;
    font-size: 20px;
    font-weight: lighter;
    width: 90%;
    align-items: center;
}

.category-content {
    margin-top: 10px;
    border: 1px solid lightgray;
    display: flex;
    background-color: white;
    width: 90%;
    align-items: center;
    flex-direction: column;
}

.category-entry {
    margin: 10px;
    border: 1px solid lightgray;
    display: flex;
    background-color: white;
    width: 100%;
    align-items: flex-start;
}

.category-icon {
    width: 73px;
    height: 60px;
    padding-top: 13px;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.category-bar {
    margin-top: 50px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.category-footer {
    margin-top: 10px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.category-icon-editor-cp {
    display: flex;
}

.category-icon-editor-viewbox {
    margin-top: -10px;
    margin-bottom: 10px;
}

.category-icon-editor-viewbox-pos {
    margin-top: 10px;
}