.tilechooser {
    background-color: white;
    border-right: 1px solid #888;
    position: absolute;
    z-index: 50;
    left: 0;
    bottom: 0;
    height: calc(100% - 93px);
    transform: translateX(70px);
    width: 400px;
    -webkit-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.35);
    -moz-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.35);
    box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.35);
    transition: transform cubic-bezier(.42,0,.52,1.2) .3s;
    padding: 5px;
    padding-left: 10px;
    overflow-y: auto;
}

.tilechooser.hidden {
    transform: translateX(-410px);
}

.tilechooser-progress-pane {
    text-align: center;
}

.tilechooser-tile {
    cursor: pointer;
    border: 1px solid gray;
    background-color: #537994;
    color: white;
    font-size: 1.1em;
    font-weight: lighter;
    border-radius: 4px;

    padding: 5px;
    margin-bottom: 10px;
}

.tilechooser-tile:focus {
    border: 2px dotted red;
}

.tilechooser-tile-title {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.tilechooser-tile-title > .card-title-category-icon {
    margin-left: -6px;
    margin-top: -6px;
    border-radius: 4px 0px 0px 0px;
    margin-right: 5px;
}

.tilechooser-tile-tags {
    display: flex;
    margin-top: 5px;
    flex-flow: wrap;
}

.tilechooser-tile-tag {
    margin: 2px;
    padding: 2px;
    font-size: 0.8em;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
    border-radius: 5px;
    background-color: #3a5466;
    border: 1px solid lightgray;
    margin-left: 5px;
}

.tilechooser-search {
    margin: 5px 5px 10px 9px;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
}

.tilechooser-search > .p-inputtext {
    width: calc(100% - 10px);
    font-size: 1.2em;
    font-weight: lighter;
    margin: 5px;
}

.tilechooser-search > .material-icons {
    margin-top: 5px;
    margin-right: 2px;
    margin-left: -5px;
    color: #666;
    border-bottom: 1px solid #929ba2;
}

.tilechooser-category-accordion {
    display: flex;
}

.tilechooser-category-accordion-icon{
    margin-right: 10px;
    height: 37px;
    padding: 5px;
    border: 1px solid #444;
}

.tilechooser-category-accordion-text{
    flex-grow: 1;
}

.p-accordion-header > a{
    display: flex !important;
}

.p-accordion-header-text {
    width: 100%;
}