.dashboard {
    width: 100%;
    height: 100%;
}

.card {
    height: 100%;
}

.table-block, 
.table-block > .p-datatable, 
.table-block > .p-datatable > .p-datatable-wrapper, 
.table-block > .p-datatable > .p-datatable-wrapper > table{
   height: 100%;
}

.tile-grid {
    display: grid;
    gap: 0px;
    position: relative;
}

.tile-source-upload-icon {
    float: left;
}

.notAllowedLabel {
    position: absolute;
    width: 100%;
    height: 100%;   
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notAllowedLabelText {
    text-align: center;
    background-color: rgba(155, 155, 155, 0.9);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 20px;
}

.sourceLink > a {
    text-decoration: underline;
}

.react-grid-item:not(.react-grid-placeholder) {
    background: #f0f0f0;
    border: 1px solid lightgray;
}

.react-grid-item {
    overflow: auto;
}

.innershadow {
    -moz-box-shadow:    inset 0 0 20px lightgray;
   -webkit-box-shadow: inset 0 0 20px lightgray;
   box-shadow:         inset 0 0 20px lightgray;
}

.process-spinner {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #EEEEEE;
}

.progress-spinner-dashboard {
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
}

.react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
}

.react-grid-item.react-grid-placeholder {
  background-color: #6ea5cc1f;
  border-width: 4px;
  border-style: dashed;
  border-color:#6ea5cc94;
  z-index: 1000;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  /*transition: width ease .3s, height ease .3s;
  /*-webkit-box-shadow: 0px 0px 12px 1px rgba(83,121,148,1);
  -moz-box-shadow: 0px 0px 12px 1px rgba(83,121,148,1);
  box-shadow: 0px 0px 12px 1px rgba(83,121,148,1);*/
}

.react-grid-layout {
    /*background-color: white;*/
    background-color: #f0f0f0;
    margin: 10px;
}

.singlenumber {
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 2.2em;
    color: #666;
    font-weight: lighter;
    letter-spacing: -1px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.tab-block {
    height: 100%;
}

.tab-block > .p-tabview {
    height: 100%;
}

.tab-block > .p-tabview > .p-tabview-panels {
    height: calc(100% - 35px);
}


.tab-block > .p-tabview > .p-tabview-panels > .p-tabview-panel {
    height: 100%;
}

.tab-block .p-tabview .p-tabview-nav > li > a {
    padding-top: 3px;
    padding-bottom: 2px;
}

.tile-source-sticky {
    background-color: white;
    text-align: end;
    color: #666;
    font-size: 0.9em;
    margin-right: 17px;
    z-index: 1;
    /*position: fixed;
    bottom: 0;
    left: 0;
    right: 0;*/
}

.tile-source-upload-date {
    white-space: nowrap;
}

.tile-source {
    border-top: 1px solid lightgray;
    margin-left: 12px;
    width: calc(100% - 17px);
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    margin-bottom: 5px;
}

.tile-source-firstrow {
    display: flex;
    margin-bottom: 4px;
}

.tile-source-secondrow {
    display: flex;
}

.tile-source-dataversiondate {
    margin-left: 5px;
}

.sourceLink.newsources {
    display: flex;
    flex-grow: 0;
}

.source-seperator {
    margin-left: 10px;
}

.sourceLink {
    flex-grow: 1;
}

.tile-source-sourcetext.newsources {
    /* flex-direction: column; */
    /* display: flex; */
    flex-wrap: wrap;
}

.tile-source-sourcetext {
    display: flex;
    flex-grow: 1;
    /* display: flex; */
}

.chart-wrapper {
    position: relative;
    padding-bottom: 40%;
}
   
.chart-inner {
    position: absolute;
    width: 100%; 
    height: 100%;
}

.highcharts-menu > hr {
    margin: 5px;
}


@-webkit-keyframes fadeIn {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
 }
 
 @keyframes fadeIn {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
 }
 