.db-settings-root {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    align-items: flex-start;
}

.db-settings-content {
    width: 80%;
    padding: 20px;
    border: 1px solid lightgray;
    background-color: white;
}

.db-settings-title {
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: lighter;
    letter-spacing: -1px;
    font-size: 1.8em;
    color: #666;
    height: 100%;
}

.db-settings-items {
    margin-top: 45px;
    margin-left: 25px;
    color: #666;
    font-weight: lighter;
    font-size: 1.2em;
}

.db-settings-items-info {
    margin-bottom: 45px;
    width: 400px;
}

.db-settings-items-info > svg {
    float: left;
    margin-right: 5px;
}

.db-settings-items > .p-grid > .label {
    font-weight: lighter;
    width: 150px;
}

.db-settings-items > .p-grid > .value > .p-inputtext, 
.db-settings-items > .p-grid > .value > .p-dropdown > .p-inputtext{
    margin-top: -1px;
    font-size: 1em;
    font-weight: lighter;
    color: #666;
}


.db-settings-items > .p-grid > .value > .p-chips > ul.p-inputtext .p-chips-input-token > .p-inputtext{
    margin-top: -6px;
    font-size: 1.2em;
    font-weight: lighter;
    color: #666;
}

.db-settings-items > .p-grid > .value > .p-chips > ul.p-inputtext .p-chips-token .p-chips-token-label {
    font-size: 1.2em;
    font-weight: lighter;
    color: white;
}

.db-settings-items > .p-grid > .value > .p-inputswitch {
    font-size: 14px;
}

.db-settings-buttons {
    margin-top: 45px;
}

.db-settings-buttons > .p-button {
    font-weight: lighter;
    font-size: 1em;
}

.dashboard-settings-category > .p-autocomplete-input {
    width: 100%;
    font-size: 1em;
    font-weight: lighter;
    color: #666;
}