.card-title {

   background-color: #537994;
   padding: 5px 8px 5px 16px;
   text-align: center;
   color: white;
   font-size: 1.2em;
   /*font-weight: lighter;*/
   display: flex;
   /*justify-content: center;*/
   justify-content: space-between;
   cursor: grab;
}

.card-title-category-icon {
   border-bottom: '1px solid lightgray';
   border-right: '1px solid lightgray';
}


.card-title:active {

   cursor: grabbing;
}

.btn-card-title-menu {
   /* margin-top: 2px; */
   margin-bottom: -5px !important;
   position: relative;
}

.btn-card-title-menu > .p-link{
   color: white;
}

.card-title > .p-link:hover{
   background-color: #eeeeee42;
}

.card-title-menu {
   position: absolute;
   background-color: white;
   border: 1px solid gray;
   padding-top: 5px;
   padding-bottom: 5px;
   top: 25px;
   right: 0;
   z-index: 1;
   max-height: 200px;
   overflow-y: auto;
   min-width: 180px;
}

.card-title-menu.hidden {
   display: none;
}

.card-title-entry > .p-link {
   display: flex;
   align-items: center;
   color: #666;
   padding-left: 10px;
   padding-right: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
   width: 100%;
   font-size: 0.8em;
   white-space: nowrap;
}

.card-title-entry > .p-link:hover {
   background-color: rgb(114, 151, 179);
   color: white;
}



.card-title-entry > .p-link > svg {
   color: #787878 !important;
   flex-shrink: 0;
   margin-right: 5px;
}

.card-title-entry:hover > .p-link > svg {
   color: #FFF !important;
}

.sticky-header {
   /*position: sticky;
   top: 0;
   left: 0;
   right: 0;
   z-index: 1;*/
}

.sticky-header.grab {
   cursor: grabbing;
}

s.card-title-icon {
   color: white;
   float: right;
   margin-top: -29px;
}

.card-subtitle-icon {
   color: #3e3e3e;
   float: right;
   margin-top: -29px;
}

.card-subtitle {
   background-color: #bfddf3;
   margin: -14px -15px 30px -15px;
   padding: 10px 16px 7px 16px;
   border-bottom-right-radius: 3px;
   border-bottom-left-radius: 3px;
}
.card-subtitle > h2{
   margin-bottom: 0px;
   margin-top: 0px;
   margin-left: 15px;
   color: #3e3e3e;
}

.seperator {
   border-top: 1px solid lightgray;
}

.card-title-functions {
   display: flex;
}

.card-title-functions > div {
   margin-left: 6px;
}


.card-title-link > .p-link {
   color: white;
}