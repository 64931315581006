.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
 
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
 
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
 
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeOut:after {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


 
@-webkit-keyframes fadeIn {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}
 
@keyframes fadeIn {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}
 
@-webkit-keyframes fadeOut {
    0% {
       opacity: 1;
    }
    100% {
       opacity: 0;
    }
}
 
@keyframes fadeOut {
    0% {
       opacity: 1;
    }
    100% {
       opacity: 0;
    }
}
 
@-webkit-keyframes fadeInRight {
    0% {
       opacity: 0;
       -webkit-transform: translateX(80px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
}
 
@keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(80px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
       opacity: 0;
       -webkit-transform: translateX(-80px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
}
 
@keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-80px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}



@-webkit-keyframes fadeInUp {
   0% {
      opacity: 0;
      -webkit-transform: translateY(80px);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
   }
}

@keyframes fadeInUp {
   0% {
      opacity: 0;
      transform: translateY(80px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}



@-webkit-keyframes fadeInDown {
   0% {
      opacity: 0;
      -webkit-transform: translateY(-80px);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
   }
}

@keyframes fadeInDown {
   0% {
      opacity: 0;
      transform: translateY(-80px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}