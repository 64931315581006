.dashboard-search-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
}

.dashboard-search-content {
    width: 100%;
    background-color: white;
    border: 1px solid lightgray;
    margin-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;
}

.search-bar .p-component {
    background-image: none !important;
}

.dashboard-search {
    padding-top: 20px;
    padding-bottom: 40px;
}

.dashboard-search-result {
    margin-bottom: 30px;
}

.dashboard-search-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.dashboard-search-title > h2 {
    margin-bottom: 5px;
}

.search-content-indicator-items {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-search-dialog-content {
    margin-left: 30px;
    margin-bottom: 30px;
}

.search-content-indicator-item-category {
    margin-left: -10px;
    margin-top: -10px;
    padding: 5px;
    border-radius: 5px 0px 5px 0px;
    margin-right: 10px;
    height: 35px;
}

.search-content-indicator-item-title {
    flex-grow: 1;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.search-content-indicator-create-no-selection-hint {
    display: flex;
    margin-right: 10px;
    text-align: end;
    border-left: 5px solid #8a680a;
    background-color: #eccb0e;
    padding: 4px 10px;
    border-radius: 5px;
}

.search-content-indicator-create-no-selection-hint > svg {
    margin-top: 1px;
    margin-right: 10px;
    flex-shrink: 0;
}

.search-content-indicator-item {
    position: relative;
    background-color: #537994;
    width: 200px;
    display: flex;
    color: white;
    margin-top: 5px;
    margin-right: 5px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    min-height: 100px;
}

.search-content-indicator-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-content-indicator-check {
    color: #005cb3;
    margin-left: 20px;
    margin-top: 6px;
    text-decoration: underline;
}

.search-content-indicator-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
}

.search-content-indicator-create {
    background-color: #f2f6fc;
    border: 2px solid #005cb3;
    display: flex;
    align-items: center;
    color: #005cb3;
    font-size: 1em;
    padding: 10px 24px;
    border-radius: 5px;
    outline: none;
}

.search-content-indicator-create.disabled {
    opacity: 0.6;
    cursor: default;
}

.search-content-indicator-create.disabled:hover {
    background-color: #f2f6fc;
    border: 2px solid #005cb3;
    color: #005cb3;
}

.search-content-indicator-create.disabled:active {
    background-color: #f2f6fc;
    border: 2px solid #005cb3;
    color: #005cb3;
}

.search-content-indicator-create.disabled:active > svg > path {
    fill: #005cb3 !important;
}

.search-content-indicator-create.disabled:hover > svg > path {
    fill: #005cb3 !important;
}

.search-content-indicator-create:hover {
    background-color: #005cb3;
    color: white;
}

.search-content-indicator-create:active {
    background-color: #0073df;
    color: white;
}

.search-content-indicator-create:active > svg > path {
    fill: white !important;
}

.search-content-indicator-create:hover > svg > path {
    fill: white !important;
}

.search-content-indicator-uncheck {
    color: #005cb3;
    margin-left: 10px;
    margin-top: 6px;
    text-decoration: underline;
}

.search-content-indicator-create > svg {
    margin-right: 10px;
}

.search-content-indicator-item.unselected {
    opacity: 0.6;
}

.search-content-indicator-item-checkbox {
    position: absolute;
    bottom: 0;
    right: 3px;
}

.dashboard-search-options { 
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 10px;
}

.search-bar-root { 
}

.search-bar-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.dashboard-search-info {
    margin-top: 5px;
    height: 100%;
    margin-left: 10px;
}

.search-bar:focus-within {
    border: 2px solid #005cb3;
}

.searchbar-values {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.searchvalue {
    border: 1px solid #272c2d;
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.searchvalue > svg {
    margin-right: 6px;
}

.searchvalue-close {
    margin-left: 8px;
}

.search-bar {
    border: 2px solid transparent;
    /* border: 1px solid lightgray; */
    background-color: #eceff1;
    flex-grow: 1;
    margin-top: 10px;
    padding: 10px 18px;
    border-radius: 3px;
    display: flex;
    color: #666;
    /* font-size: 20px; */
    /* font-weight: lighter; */
    align-items: center;
    height: 53px;
}

.search-bar .p-autocomplete-panel {
    top: 40px !important;
    left: -50px !important;
    right: -18px !important;
}

.dashboard-search-hinttext {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 700px;
    display: flex;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 25px;
}

.dashboard-search-hinttext > svg {
    width: 32px !important;
    flex-shrink: 0;
    margin-right: 10px;
}

.search-bar > .p-float-label {
    text-align: center;
    font-size: 24px;
    font-weight: lighter;
}

.search-bar > .p-inputtext {
    /* text-align: center; */
    /* font-size: 24px; */
    padding: 6px 0px;
    font-weight: lighter;
    width: 100%;
}

.search-bar > .material-icons {
    margin-right: 10px;
    /* margin-right: 10px; */
}

.search-content .p-inputswitch {
    font-size: 13px;
}

.search-content {
    border: 1px solid lightgray;
    margin-top: 40px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    color: #666;
    font-size: 20px;
    font-weight: lighter;
    flex-direction: column;
}

.search-content-title {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.search-content-title-title {
    flex-grow: 1;
    margin-left: 10px;
}

.search-page-input {
    width: 100%;
}

.search-content-entry {
    /* border: 1px solid lightgray; */
    /* margin: 5px; */
    /* background-color: #537994; */
    -webkit-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
-moz-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
    background-color: #f5efef;
    width: 100%;
    /* color: white; */
    color: #212121;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
}

.search-content-entry-tags {
    flex-grow: 1;
    align-items: flex-end;
    text-align: end;
    margin-right: 10%;
    display: flex;
    justify-content: flex-end;
}

.search-content-entry-tag {
    font-size: 0.8em;
    /* padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px; */
    padding: 2px 5px;
    border-radius: 3px;
    /* background-color: #e6511a; */
    background-color: #7297b3;
    color: #fff;
    /* border: 1px solid lightgray; */
    margin-left: 5px;
    height: 80%;
}

.search-content-entry-title {
    margin: 10px;
    flex-grow: 1;
}
.search-content-entry-abo {
    margin-right: 10px;
    margin-top: -5px;
}

.search-content-entry:hover {
    /* background-color: #7297b3; */
    background-color: #e8e4e4;
}

.search-content-display-mode-button {
    box-shadow: none !important;
    border: 1px solid lightgray !important;
    padding: 3px 10px !important;
    /* border-radius: 3px !important; */
    /* margin-right: 0 !important; */
}

.search-content-display-mode-button.button-inactive {
    background-color: transparent !important;
}

.search-content-display-mode-button.button-active {
    background-color: lightgray !important;
}

.search-content-display-mode-button .pi{
    color: #666 !important;
}

.search-content-title .search-content-display-mode-button:first-child {
    border-radius: 3px 0px 0px 3px !important;
    margin-right: 0 !important;
}

.search-content-title .search-content-display-mode-button:nth-child(2) {
    border-radius: 0px 3px 3px 0px !important;
}

.search-content > .p-tree{
    width: 100%;
    padding: 0;
}

.search-content .p-tree-container.search-category-tree {
    padding: 0;
}

.search-content .p-tree-container.search-category-tree > li.p-treenode {
    border-bottom: 1px solid #ddd;
}

.search-content .p-tree-container.search-category-tree > li.p-treenode:last-child {
    border-bottom: 0;
}

body .p-tree .p-tree-container.search-category-tree  .p-treenode .p-treenode-content .p-treenode-label {
    width: calc(100% - 29px);
    padding: 0 ;
}

.search-category-label {
    display: inline-block;
    padding: 8px;
}

.search-list-mode > .search-content-entry {
    margin-bottom: 6px;
}

.search-list-mode > .search-content-entry:last-child {
    margin-bottom: 0;
}

.p-treenode-content:focus {
    outline: none;
}