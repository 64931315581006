.category-overview {
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: flex-start;
    height: 100%;
}

.category-overview-content {
    width: 90%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.category-overview-cat {
    width: 49%;
    display: flex;
    border: 1px solid gray;
    margin: 5px 5px 5px 5px;
    cursor: pointer;
    align-items: center;
}

.category-overview-cat:hover {
    background-color: lightgray;
}

.category-overview-icon {
    width: 73px;
    height: 60px;
    min-width: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid gray;
}

.category-overview-text {
    margin-top: -5px;
    margin-left: 10px;
    margin-right: 25px;
    font-size: 1.2em;
    letter-spacing: -.2px;
    line-height: normal;
    font-weight: lighter;
}

.category-overview-title {
    font-size: 1.4em;
    font-weight: lighter;
}

@media only screen and (max-width: 1300px) {
    
    .category-overview-cat {
        width: 100%;
    }
}