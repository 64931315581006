.icon
{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-container
{
    width: 100px;
    margin: 10px;
    height: 80px;
}

.icon-container > .icon
{
    width: 100%;
    height: 100%;
}

.p-dialog-titlebar 
{
    font-size: 20px;
    font-weight: 400;
    line-height: 0em;
    padding: 0px 0px 0px 15px !important;
    margin-bottom: 10px;
}

.dialog-text
{
    font-size: 14px;
    margin-top: 10px;
}

.dialog-content
{
    margin-top: 20px;
}

.p-dialog-titlebar-close
{
    margin-right: 5px;
}

.info
{
    background-image: url(./images/info.png);  
}

.warning
{
    background-image: url(./images/warning.png);  
}

.question
{
    background-image: url(./images/question.png);  
}

.dialog-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #666;
    opacity: 0.6;
    z-index: 999;
}
.dialog-overlay.hidden{
    display: none;
}