@media only screen and (max-width: 800px) {
    
    .topbar-path {
        display: none;
    }

    .topbar-burger-menu {
        display: flex;
    }

    .sidebar {
        transform: translateX(-75px);
    }

    .destatis-logo {
        display: none;
    }

    .sidebar-mobile-active {
        transition: transform 0.5s;
        transform: translateX(0px);
        border-right: none;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
    }
    
    .content-sidebar-mobile-active {
        transition: transform 0.5s;
        transform: translateX(75px);
        width: 100%;
        height: 100%;
    }
    
    .overlay-sidebar-mobile-active {
        z-index: 999998;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #424242;
        display: block;
        opacity: 0.5;
        filter: alpha(opacity=50);
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .sidebar-mobile-active .hover-menu.mobile-menu-active{
        visibility: hidden;
        border: 0;
        width: calc(100vw - 84px);
        height: 60px;
        background: transparent;
        max-width: max-content;
    }

    .sidebar-mobile-active .hover-menu.mobile-menu-active .hover-menu-title{
        word-break: break-word;
        white-space: normal;
        height: 100%;
        text-align: left;
        float: left;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.75);
    }

    .inner-content {
        width: 100%;
        margin-left: 0px;
    }

    .content-full {
        width: 100%;
    }

    .topbar {
        width: 100%;
        padding: 0px 0px 0px 0px;
    }

    .search-content-entry-tags {
        display: none;
    }

    .tilechooser {
        transform: translateX(0px);
    }
  }