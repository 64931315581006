.destatis-loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 10px;
}

.destatis-loading-bar-horizontal {
    display: flex;
    flex-direction: row;
}

.destatis-loading-bar-titel-horizontal {
  margin-left: 15px;
}

.destatis-loading-bar-center {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 95px;

}

.destatis-loading-bar-titel {
    font-size: 1.2em;
}

.destatis-loading-bar { 
    transform-origin:  150px 150px!important ;
}

.destatis-loading-bar-small { 
  transform-origin:  150px 150px!important ;
}

.destatis-loading-bar > div {
  width: 100%;
  height: 100%;
}

.destatis-loading-bar-small > div {
  width: 100%;
  height: 100%;
}

.destatis-loading-bar > div > div {
  position: absolute;
  width: 24px;
  height: 80px;
  bottom: 0px;
  animation: destatis-loading-bar 3.0303030303030303s cubic-bezier(0.5,0,.5,1) infinite;
}

.destatis-loading-bar-small > div > div {
  position: absolute;
  width: 5px;
  height: 20px;
  bottom: 0px;
  animation: destatis-loading-bar-small 3.0303030303030303s cubic-bezier(0.5,0,.5,1) infinite;
}

.destatis-loading-bar > div > div:nth-child(1) {
  left: 20px;
  background: #000000;
  animation-delay: -1.2121212121212122s;
}

.destatis-loading-bar-small > div > div:nth-child(1) {
  left: 2px;
  background: #000000;
  animation-delay: -1.2121212121212122s;
}

.destatis-loading-bar > div > div:nth-child(2) {
  left: 60px;
  background: #cc0033;
  animation-delay: -0.6060606060606061s;
}

.destatis-loading-bar-small > div > div:nth-child(2) {
  left: 10px;
  background: #cc0033;
  animation-delay: -0.6060606060606061s;
}

.destatis-loading-bar > div > div:nth-child(3) {
  left: 100px;
  background: #ffcc00;
  animation-delay: -1.8181818181818181s;
}

.destatis-loading-bar-small > div > div:nth-child(3) {
  left: 18px;
  background: #ffcc00;
  animation-delay: -1.8181818181818181s;
}

.destatis-loading-bar-anim {
  width: 150px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.destatis-loading-bar-anim-small {
  width: 25px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.destatis-loading-bar {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.destatis-loading-bar-small {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.destatis-loading-bar div { 
    box-sizing: content-box; 
}

@keyframes destatis-loading-bar 
{
    0% { height: 60px; }
    33.33% { height: 100px }
    66.66% { height: 40px }
    100% { height: 60px }
}

@keyframes destatis-loading-bar-small
{
    0% { height: 15px; }
    33.33% { height: 20px }
    66.66% { height: 6px }
    100% { height: 15px }
}