.p-toast > div {
    perspective: 500px;

}

.p-toast-message {
    margin: 10px;
    margin-left: 2.5em !important;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
    -webkit-animation-name: toast-fadeInUp;
    animation-name: toast-fadeInUp;
    animation-timing-function: cubic-bezier(0.110, 1.075, 0.385, 0.910);
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.p-toast-message-exit-active {
    -webkit-animation-name: toast-fadeInUp;
    animation-name: toast-fadeInUp;
    animation-timing-function: cubic-bezier(0.110, 1.075, 0.385, 0.910);
    -webkit-animation-duration: 2.4s;
    animation-duration: 2.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.p-toast-message-content {
    padding: 10px 10px 10px 5px;
}

.p-toast-message-text {
    display: flex;
    flex-direction: column;
}

.p-toast-message-icon {
    margin-right: 5px;
}

.p-toast-summary {
    margin-top: 2px;
    margin-bottom: 10px;
}

.p-toast-icon-close-icon.pi.pi-times {
    /* display: none; */
    color: white;
}

.p-toast-message-success {
    border-left: 5px solid #1c5c28;
    background-color: #407b39 !important;
}

.p-toast-message-error {
    border-left: 5px solid #5c1c1c;
    background-color: #972d2d !important;
}

.p-toast-message-warn {
    border-left: 5px solid #8a680a;
    background-color: #eccb0e !important;
    color: black;
}

.p-toast-message-info {
    border-left: 5px solid #0003d1;
    background-color: #222775 !important;
}

@-webkit-keyframes toast-fadeInUp {
    0% {
       opacity: 0;
       -webkit-transform: scale(.6) rotateX(-60deg);
    }
    100% {
       opacity: 1;
       -webkit-transform: scale(1) rotateX(0deg);
    }
 }
 
 @keyframes toast-fadeInUp {
    0% {
       opacity: 0;
       transform: scale(.6) rotateX(-60deg);
    }
    100% {
       opacity: 1;
       transform: scale(1) rotateX(0deg);
    }
 }