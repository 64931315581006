.selectbutton-root {

}

.selectbutton-content {

}


.selectbutton:first-child {
    border-radius: 10px 0px 0px 10px;
    border-right:  none;
    border-left:  2px solid #bbb;
}

.selectbutton.selected-before {
    border-left:  none;
}

.selectbutton:last-child {
    border-radius: 0px 10px 10px 0px;
    /* border-left: none; */
    border-right:  2px solid #bbb;
}

.selectbutton {
    background: none;
    padding: 7px 20px;
    border-top: 2px solid #bbb;
    border-left: 2px solid #bbb;
    border-right:  none;
    border-bottom: 2px solid #bbb;
    background-color: #fff;
    outline: none;
}

.selectbutton:hover {
    cursor: pointer;
}

.selectbutton.selected {
    /* border: none; */
    background: none;
    border: 2px solid #005cb3;
    background-color: #f2f6fc;
}

.selectbutton-label {
}

.selectbutton.selected > .selectbutton-label {
    color: #005cb3
}

.selectbutton > .selectbutton-label {
    color: #bbbbbb
}