html {
    overflow: auto;
    
}

body .p-autocomplete-panel {
    display: block !important;
}

.p-growl-message {
    margin-left: 25px !important;
}

.p-toast-top-right {
    top: 95px;
}

span.p-growl-icon-close-icon.pi.pi-times {
    display: none !important;
}

body .p-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.p-autocomplete-token-icon {
    margin-left: 45px;
    margin-right: 4px;
    margin-top: -6px;
}

.p-autocomplete-token-label {
    margin-right: 25px !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.dashboard-toolbar-tags .p-autocomplete-token-icon {
    margin-right: 5px;
}

.app-root {
    height: 100%;
}

.link-button {
    text-decoration: underline;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: url("./img/trianglify3.svg");
}


.p-chart {
    height: 100%;
}

.content-full {
    height: calc(100% - 50px);
    width: calc(100% - 75px);
    float: right;
}

.content-full.cockpit-version {
    width: 100%;
}

.inner-content {
    height: calc(100%);
    float: left;
    width: 100%;
    /* margin-top: 65x; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 4px;
    display: flex;
    flex-direction: column;
}

.inner-content > div:not(.version-number) {
    flex-grow: 1;
}

.inner-content.cockpit-version {
    height: 100%;
}

.main {
    /*background-color: white;*/
    background-color: #f0f0f0;
    height: 100%;
    /*transition: -webkit-filter ease .2s, filter ease .2s;*/
}

.main.overflow-hidden {
    overflow: hidden;
}

.main.blur {
    height: 100%;
    filter: blur(4px);
    -webkit-filter: blur(4px); 
}

.main-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #424242;
    transition: opacity ease .2s;
    opacity: 0.5;
}

.main-overlay.hidden {
    opacity: 0;
    visibility: hidden;
}


.login-parent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-parent.hidden {
    visibility: hidden;
}

.max-width {
    width: 100%;
}

.p-messages {
    margin-top: 0px !important;
}

body .p-datatable .p-datatable-tbody > tr > td,
body .p-datatable .p-datatable-thead > tr > th {
    overflow: hidden;
    padding: 0.325em 0.875em;
}

.p-accordion-tab:focus-within {
    border: 1px dotted gray;
}

body {
    font-size: .9em;
}

.app-versionnumber {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem; 
    background-color: #efefef;
    text-align: center;
    font-size: .9em;
    color: #999;
}

.progress-spinner-anim-disabled > .p-progress-spinner-svg > .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite,p-progress-spinner-color 0s ease-in-out none;
    stroke: white;
}

.icon-button .progress-spinner-anim-disabled > .p-progress-spinner-svg > .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite,p-progress-spinner-color 0s ease-in-out none;
    stroke: #555555;
}

.link-button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /*input has OS specific font-family*/
    color: #6f747b;
    cursor: pointer;
    font-size: 13px;
}

.link-button:hover {
    color: #363636;
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon-button-loading {
    position: absolute;
    top: 1px;
}

ul {
    list-style: disc;
    margin-left: 30px;
}

.version-number {
    background-color: #f0f0f0;
    font-size: .8rem;
    text-align: center;
    color: #888;
    border-top: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding-left: 75px;
    /*
    background-color: white;
    border-top: 1px solid lightgray;*/
}

.lang-switcher {
    margin-right: 20px;
    cursor: pointer;
    align-items: center;
}

.language-icon {
    display: flex;
    border-radius: 50%;
}

.language-text {
    margin-left: 3px;
}

.global-tooltip {
    position: absolute;
    z-index: 10000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.global-tooltip-arrow-left {
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    
    border-right:7px solid #3e3e3e; 
}

.global-tooltip-arrow-right {
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    
    border-left:7px solid #3e3e3e; 
}

.global-tooltip-arrow-top {
    width: 0; 
    height: 0; 
    border-right: 7px solid transparent;
    border-top: 7px solid #3e3e3e; 
    
    border-left:7px solid transparent; 
}

.global-tooltip-arrow-bottom {
    width: 0; 
    height: 0; 
    border-right: 7px solid transparent;
    border-bottom: 7px solid #3e3e3e; 
    
    border-left:7px solid transparent; 
}

.global-tooltip-label {
    background-color: #3e3e3e;
    padding: 5px 10px 5px 10px;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
}

.tt-fadeInRight {
    -webkit-animation-name: tt-fadeInRight;
    animation-name: tt-fadeInRight;
    animation-timing-function: cubic-bezier(0.110, 1.075, 0.385, 0.910);
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
 
.tt-fadeInLeft {
    -webkit-animation-name: tt-fadeInLeft;
    animation-name: tt-fadeInLeft;
    -webkit-animation-duration: .2s;
    animation-timing-function: cubic-bezier(0.170, 1.175, 0.420, 0.905);
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


.tt-fadeInUp {
    -webkit-animation-name: tt-fadeInUp;
    animation-name: tt-fadeInUp;
    animation-timing-function: cubic-bezier(0.110, 1.075, 0.385, 0.910);
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
 
.tt-fadeInDown {
    -webkit-animation-name: tt-fadeInDown;
    animation-name: tt-fadeInDown;
    animation-timing-function: cubic-bezier(0.110, 1.075, 0.385, 0.910);
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.react-grid-item {
    transition: all 800ms cubic-bezier(.19,1,.22,1);
}


.no-script {
    background-color: #ff00002e;
    border: 2px dashed #cc0033;
    padding: 5px;
    margin-top: 25px;
    
    margin-bottom: -25px;
  
  }
  
  .splash-loader-container {
    text-align: center;
    position: absolute;
    background-color: white;
    padding: 40px 50px 40px 50px;
    width: 100%;
    max-width: 600px;
    /*border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);*/
  }
  
  .destatis-loading-logo {
  }
  
  .splash-screen-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -720px;
    z-index: -1;
    margin-top: -300px;
  }
  
  .destatis-loading-bar-titel {
    margin-top: 25px;
  }
  
  .destatis-loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .destatis-loading-bar-center {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  
  }
  
  .destatis-loading-bar-titel {
    font-size: 1.2em;
  }
  
  .destatis-loading-bar-splash { 
    transform-origin:  15px 15px!important ;
  }
  
  .destatis-loading-bar-splash > div {
    width: 100%;
    height: 100%;
  }
  
  .destatis-loading-bar-splash > div > div {
    position: absolute;
    width: 4px;
    height: 16px;
    bottom: 0px;
    animation: destatis-loading-bar-splash 3.0303030303030303s cubic-bezier(0.5,0,.5,1) infinite;
  }
  
  .destatis-loading-bar-splash > div > div:nth-child(1) {
    left: 0px;
    background: #000000;
    animation-delay: -1.2121212121212122s;
  }
  
  .destatis-loading-bar-splash > div > div:nth-child(2) {
    left: 6px;
    background: #cc0033;
    animation-delay: -0.6060606060606061s;
  }
  
  .destatis-loading-bar-splash > div > div:nth-child(3) {
    left: 12px;
    background: #ffcc00;
    animation-delay: -1.8181818181818181s;
  }
  
  .destatis-loading-bar-anim-splash {
    width: 20px;
    height: 16px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  
  .destatis-loading-bar-splash {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  
  .destatis-loading-bar div { 
    box-sizing: content-box; 
  }

  .DocDefaults {
      font-family: 'Calibri';
  }

  @keyframes destatis-loading-bar-splash 
{
  0% { height: 7px; }
  33.33% { height: 16px }
  66.66% { height: 4px }
  100% { height: 7px }
}

@-webkit-keyframes tt-fadeInRight {
    0% {
       opacity: 0;
       -webkit-transform: translateX(30px) scale3d(1.2,.2,1);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
}
 
@keyframes tt-fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(30px) scale3d(1.2,.2,1);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}

@-webkit-keyframes tt-fadeInLeft {
    0% {
       opacity: 0;
       -webkit-transform: translateX(-30px) scale3d(1.2,.2,1);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
}
 
@keyframes tt-fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-30px) scale3d(1.2,.2,1);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}



@-webkit-keyframes tt-fadeInUp {
   0% {
      opacity: 0;
      -webkit-transform: translateY(30px) scale3d(1.2,.2,1);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
   }
}

@keyframes tt-fadeInUp {
   0% {
      opacity: 0;
      transform: translateY(30px) scale3d(1.2,.2,1);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}



@-webkit-keyframes tt-fadeInDown {
   0% {
      opacity: 0;
      -webkit-transform: translateY(-30px);
   }
   100% {
      opacity: 1;
      -webkit-transform: translateY(0);
   }
}

@keyframes tt-fadeInDown {
   0% {
      opacity: 0;
      transform: translateY(-30px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}
